
export const projectData = [
  {
    title: "Mobile Food Ordering Platform",
    description: `
    <div style="text-align: center;"><b>NextGen Kitchens - Software Developer - Aug 2021 - Present</b></div>
- Managing a white-label app offered to restaurants / ghost kitchens as a personalized iOS, Android, web, in-store kiosk, & in-store POS app solution. 

- Managing a Firestore database that syncs menu data from & order data to the POS through API calls & webhooks operated by custom cloud functions.

- Integrated app and backend processes with Square (Payments, Catalog, Terminal), Stripe (Payments, Connect, Terminal), Twilio, SendGrid, DoorDash Drive, and Deliverect APIs.

- Created process for both card-present (in-store pinpad) and card-not-present (web, mobile, Apple Pay, Google Pay) orders for multiple payment providers (Stripe, Square). 

- Added ability to print a receipt directly from a kiosk or POS by connecting app through bluetooth to a Star or Epson printer.

- Worked with DoorDash to approve an integration for delivery services through DoorDash Drive. 

- Created a loyalty program to manage, collect, and spend loyalty points on custom-set rewards. 

- Created a new user in-app referral program, added custom-set discounts by selection or code entry with redemption limits, spending requirements. 

- Implemented option for a fully-automated and instantaneous multi-payment splitting between separate restaurant brands in one order (Stripe Connect API).

<a href="https://play.google.com/store/apps/details?id=com.nextgenkitchens.memphisbbq&hl=en" target="_blank" rel="noopener noreferrer">Example Android App</a> / <a href="https://apps.apple.com/ca/app/memphis-blues-bbq-house/id1629971818" target="_blank" rel="noopener noreferrer">Example iOS App</a> / <a href="https://memphisbbq.nextgenkitchens.com" target="_blank" rel="noopener noreferrer">Example web app</a> / <a href="https://nexteats.web.app" target="_blank" rel="noopener noreferrer">Web app 2</a>
    `,
    ghLink: "",
    icons: ['fab fa-react', 'fab fa-js', 'fab fa-google', '', ''],
    media: [
      { type: 'image', src: './img/nextEats1.jpg' },
      { type: 'image', src: './img/goodBowl3.jpg' },
      { type: 'image', src: './img/goodBowl1.png' },
      { type: 'image', src: './img/goodBowl2.jpg' }
    ]
  },
  {
    title: "Wordpress Website",
    description: `
    <div style="text-align: center;"><b>Above Ground Art Supplies - Wordpress & PHP Developer Intern - Nov 2020 - June 2021</b></div>
- Custom Theme Development: Implemented custom themes using PHP and WordPress to match the company's brand, improve user experience, and improve mobile responsiveness.

- Plugin Integration: Installed and customized plugins to add new functionalities to the site, including a promotions gallery, locations page, and shipping customizations.

- Bug Fixes and Updates: Resolved bugs and regularly updated the WordPress core, plugins, and themes to ensure smooth operation and security.

- Collaboration with Marketing and Design Teams: Worked closely with other departments to implement design changes and marketing strategies directly into the website.

<a href="https://www.abovegroundartsupplies.com" target="_blank" rel="noopener noreferrer">Link to website</a> 
    `,
    ghLink: "",
    icons: ['fab fa-js', 'fab fa-html5', 'fab fa-css3-alt', 'fab fa-php'],
    media: [ { type: 'image', src: './img/ag1.png' },
      { type: 'image', src: './img/ag2.png' }
    ]
  },
  {
    title: "Interactive Farm Simulation Game",
    description: `Plant, water, and collect crops while fighting off your neighbour who tries to steal them before you can harvest! Work in progress - built with native Javascript and the P5.js library for animations.
    <a href="https://lucas.gold/games/game.html" target="_blank" rel="noopener noreferrer">Link to play</a>
    `,
    ghLink: "https://github.com/lucas-gold/portfolio-site-V2",
    icons: ['fab fa-js'],
    media: [ { type: 'video', src: './img/gamedemo.webm' }]
  },
  {
    title: "Dynamic Portfolio Website",
    description: "Crafted with React, this portfolio site features dynamic animations with Framer Motion, sleek styling with Bootstrap, and interactive elements using tsparticles. Hosted on Firebase, it highlights my technical expertise and creative flair.",
    ghLink: "https://github.com/lucas-gold/search-engine",
    icons: ['fab fa-react', 'fab fa-js', 'fab fa-html5', 'fab fa-css3-alt'],
    media: [
      { type: 'image', src: './img/port1b.png' },
      { type: 'image', src: './img/port2b.png' }
    ]
  },
  {
    title: "Custom Search Engine",
    description: "Utilizing Apache Nutch and MongoDB, this search engine crawls the internet, indexing and ranking articles based on query relevance using advanced weight calculations. Frontend built with Java.",
    ghLink: "https://github.com/lucas-gold/word-filler",
    icons: ['fab fa-java'],
    media: [
      { type: 'image', src: './img/search_1.png' },
      { type: 'image', src: './img/search_2.png' }
    ]
  }, 
  {
    title: "AI-Enhanced Location Search",
    description: "An AI-enhanced destination search that curates popular destinations worldwide. Built with AngularJS, PHP, and MySQL, it allows natural language searches to display matching attractions, offering personalized travel suggestions.",
    ghLink: "https://github.com/lucas-gold/word-packer",
    icons: ['fab fa-angular', 'fab fa-html5', 'fab fa-css3-alt', 'fab fa-php'],
    media: [
      { type: 'image', src: './img/vacation_1.png' },
      { type: 'image', src: './img/vacation_2.png' }
    ]
  },
  {
    title: "AI-Driven Bridge Card Game Bidder",
    description: "Employing algorithms using Elixir, this program determines the optimal bid for a 4-player bridge game based on a given deck of 52 cards.",
    ghLink: "https://github.com/lucas-gold/bridge-bidding-elixir",
    icons: ['./img/icons/elixir.svg'],
    media: [
      { type: 'image', src: './img/bridge_1.png' },
      { type: 'image', src: './img/bridge_2.png' }
    ]
  }
];