import * as React from "react";
import { useState } from "react";
import { motion, AnimateSharedLayout, AnimatePresence } from "framer-motion";
import "./projectstyle.css";
import {
  projectData
} from "./project-data";

export default function App() {
  return (
    <AnimateSharedLayout>
      <div className="pjul">
        <motion.ul layout initial={{ borderRadius: 25 }}>
          {projectData.map((item, index) => (
            <p key={index} data={item}></p>
          ))}
        </motion.ul>
      </div>
    </AnimateSharedLayout>
  );
}

export const Project = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <motion.li
    className="pjli"
    layout
    onClick={toggleOpen}
    initial={{ borderRadius: 25 }}
  >
    <AnimatePresence>
      {!isOpen && (
         <motion.div
         layout
         initial={{ opacity: 1 }}
         animate={{ opacity: 1 }}
         exit={{ opacity: 0 }}
         style={{width: '100%'}}
       >
         <div>
           <div className="title-container">
             <div className="left-icons">
               {data.icons.map((icon, index) => (
                 <i key={index} className={icon}></i>
               ))}
             </div>
             <h4 className="title">{data.title}</h4>
             <div className="right-icons">
              {
                data.ghLink ?  <a href={data.ghLink} target="_blank" rel="noopener noreferrer" style={{color: 'black'}}>
                <i className="fab fa-github"></i>
              </a> : <h6>{'(Work project)'}</h6>
              }
              
             </div>
           </div>
           
         </div>
       </motion.div>
      )}
    </AnimatePresence>

    <AnimatePresence>
      {isOpen && (
        <motion.div
          layout
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div style={{ paddingBottom: '20px' }}>
            <div className="title-container">
              <div className="left-icons">
                {data.icons.map((icon, index) => (
                  <i key={index} className={icon}></i>
                ))}
              </div>
              <h4 className="title">{data.title}</h4>
              <div className="right-icons">
                {
                  data.ghLink ? <a href={data.ghLink} target="_blank" rel="noopener noreferrer" style={{color: 'black'}}>
                  <i className="fab fa-github"></i>
                </a> : <h6>{'(Work project)'}</h6>
                }
               
              </div>
            </div>
            <p className="desctext" dangerouslySetInnerHTML={{ __html: data.description }}></p>
            <div style={{ display: "grid", gridGap: "40px" }}>
              {data.media.map((media, index) => (
                <div key={index} className="media-container">
                  {media.type === 'video' ? (
                    <video controls width="100%">
                      <source src={media.src} type="video/webm" />
                      Sorry, your browser doesn't support videos.
                    </video>
                  ) : (
                    <a href={media.src} target="_blank" rel="noopener noreferrer">
                      <img
                        alt=""
                        width='100%'
                        src={media.src}
                        style={{ borderRadius: "15px"}}
                      />
                    </a>
                  )}
                </div>
              ))}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  </motion.li>
  );
};
